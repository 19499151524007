<template>
<!-- 日志管理 -->
  <div class="whiteBg">
    <div class="flexAC" style="margin: 16px 0px;">
      <el-input v-model="logcode"  placeholder="请输入请求人员/接口名称" class="inputSearch"></el-input>
      <el-button type="primary" class="btnSearch"  @click="handleSearch">搜索</el-button>
      <el-button class="btnReset"  @click="handleReset">重置</el-button>
    </div>
    <div class="table_list">
      <el-table
        :data="logList"
        :header-cell-style="{background:'#EBF2FE'}">
        <el-table-column
          label="序号"
          type="index">
        </el-table-column>
        <el-table-column
          label="请求地址"
          prop="logcode">
        </el-table-column>
        <el-table-column
          label="提示信息"
          prop="message">
        </el-table-column>
        <el-table-column
          label="请求类型"
          prop="logtype">
        </el-table-column>
        <el-table-column
          label="请求时间"
          prop="logtime">
        </el-table-column>
        <el-table-column
          label="请求人员"
          prop="sname">
        </el-table-column>
        <el-table-column
          label="接口状态"
          prop="logresult">
          <template slot-scope="scope">
            <span v-if="scope.row.logresult == true">成功</span>
            <span v-if="scope.row.logresult == false">失败</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="pageChange"
      :current-page="page"
      :page-sizes="[10]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="settingPage">
    </el-pagination>
  </div>
</template>

<script>
import { logGet } from '@/api/system.js'
export default {
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      logcode: '',
      logList: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const params = {
        logcode: this.logcode,
        page_index: this.page,
        page_size: this.size
      }
      logGet(params).then(res => {
        if (res.status === 1) {
          this.logList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.logList = []
          this.total = 0
        }
      })
    },
    pageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.init()
    },
    handleReset () {
      this.logcode = ''
      this.init()
    }
  }
}
</script>

<style>

</style>
